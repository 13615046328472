<template>
  <div
    class="v-application v-application--is-ltr theme--light menuable__content__active"
  >
    <!--begin::ListCustomerBranch-->
    <div class="row">
      <div class="col-xxl-12 col-12 col-md-12">
        <b-card>
          <b-button
            variant="primary gutter-b"
            @click="handleButtonAdd()"
            v-if="
              permission.some(
                (item) => item.name === 'can_create_customer_branch'
              )
            "
            >Tambah</b-button
          >
          <div class="row justify-content-between">
            <div class="col-md-1">
              <b-form-group class="mb-0">
                <b-form-select
                  id="per-page-select"
                  v-model="refPerpage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group class="mb-0">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text>
                      <i class="menu-icon flaticon-search"></i>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="params.query"
                    placeholder="Search"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <div class="table-responsive">
            <b-table
              hover
              :items="tableData"
              :fields="columns"
              :per-page="refPerpage"
              :current-page="currentPage"
              :busy="isLoading"
              :filter="params.query"
              show-empty
            >
              <template #cell(id)="row">
                <span class="mr-2">
                  <span
                    :title="row.item.id"
                    class="cursor-pointer"
                    v-clipboard:copy="row.item.id"
                    v-clipboard:success="onCopy"
                    >{{ sortChar(row.item.id) }}</span
                  >
                  <span> </span>
                </span>
              </template>
              <template #cell(action)="row">
                <div style="width: 150px" class="d-flex">
                  <span class="mr-2" @click="handleView(row.item)">
                    <b-button
                      variant="primary"
                      class="py-1 px-2"
                      id="detail"
                      title="detail"
                    >
                      <i class="menu-icon flaticon-eye pr-0"></i>
                    </b-button>
                  </span>
                  <span class="mr-2" @click="handleEdit(row.item)">
                    <b-button
                      variant="success"
                      class="py-1 px-2"
                      id="detail"
                      title="detail"
                    >
                      <i class="menu-icon flaticon-edit pr-0"></i>
                    </b-button>
                  </span>
                  <span class="mr-2" @click="handleQR(row.item)">
                    <b-button
                      class="py-1 px-2"
                      id="QR"
                      title="QR code"
                      variant="info"
                    >
                      <i
                        class="menu-icon far fa-file px-1"
                        style="color: #ffffff"
                      ></i>
                    </b-button>
                  </span>
                </div>

                <!-- <span @click="handleDelete(row.item)">
                  <b-button
                    variant="danger"
                    class="py-1 px-2"
                    id="detail"
                    title="detail"
                  >
                    <i class="menu-icon flaticon-delete pr-0"></i>
                  </b-button>
                </span> -->
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
          </div>
          <div class="row justify-content-end">
            <div class="col-md-3">
              <b-pagination
                v-model="refCurrentPage"
                :total-rows="totalData"
                :per-page="refPerpage"
                size="md"
                align="fill"
                class="ml-auto"
                style="padding: 0"
              ></b-pagination>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <!--end::ListCustomerBranch-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { sortChar } from "@/utils/helper";
import { debounce } from "debounce";
import { mapGetters } from "vuex";

export default {
  name: "ListCustomerBranch",
  data() {
    return {
      selected: null,
      isLoading: false,
      rowStyleOption: {
        stripe: true,
      },
      search: "",
      pageIndex: 1,
      totalRows: 10,
      currentPage: 1,
      pageSize: 10,
      perPage: 10,
      pageOptions: [10, 15, { value: 100, text: "Show a lot" }],
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      columns: [
        {
          key: "id",
          label: "ID",
          align: "left",
          width: "5%",
          sortBy: "",
        },
        {
          key: "name",
          label: "Name",
          align: "left",
          sortBy: "",
          width: "",
          sortable: true,
        },
        {
          key: "address",
          label: "Address",
          align: "left",
          sortBy: "",
          width: "30%",
          sortable: true,
        },
        {
          key: "village.name",
          label: "Village",
          align: "left",
          width: "",
          sortBy: "",
          sortable: true,
        },
        {
          key: "district.name",
          label: "District",
          align: "left",
          width: "",
          sortBy: "",
          sortable: true,
        },
        {
          key: "regency.name",
          label: "Regency",
          align: "left",
          width: "",
          sortBy: "",
          sortable: true,
        },
        {
          key: "province.name",
          label: "Province",
          align: "left",
          width: "",
          sortBy: "",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          align: "center",
          width: "40%",
        },
      ],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      listTable: [],
      totalData: 10,
      filter: null,
      filterOn: [],
      params: {
        query: "",
        sort: "created_at",
        order: "desc",
        limit: 10,
        offset: 0,
      },
      timer: 0,
    };
  },
  components: {},
  computed: {
    ...mapGetters(["permission"]),
    tableData() {
      return this.listTable;
    },
    refCurrentPage: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.params.offset = (value - 1) * this.params.limit;
      },
    },
    refPerpage: {
      get() {
        return this.perPage;
      },
      set(value) {
        this.perPage = value;
        this.params.limit = value;
      },
    },
    refSortBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.sortBy = value;
        this.params.sort = value;
      },
    },
    refSortDirectionChange: {
      get() {
        return this.sortDirection;
      },
      set(value) {
        this.sortDirection = value;
        this.params.order = value;
      },
    },

    sortOptions() {
      return this.columns
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.getTabel();
    let branchTitle = [
      {
        title: "Site Group",
        route: "/customer",
      },
      {
        title: "Site",
      },
    ];
    if (!this.$route.params.customerId) branchTitle = [{ title: "Site" }];
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      ...branchTitle,
    ]);
  },
  methods: {
    sortChange(params) {
      Object.entries(params).forEach((item) => {
        if (item[1]) {
          // eslint-disable-next-line
          console.log(item[0], item[1]);
        }
      });
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
    },
    // page size change
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    },
    handleButtonAdd() {
      let link = "/customer/branch/create";
      if (this.$route.params.customerId)
        link = `/customer/branch/${this.$route.params.customerId}/create`;
      this.$router.push({ path: link });
    },
    handleView(item) {
      this.$router.push({ path: `/customer/branch/view/${item.id}` });
    },
    handleEdit(item) {
      this.$router.push({ path: `/customer/branch/edit/${item.id}` });
    },
    handleQR(item) {
      this.$router.push({ path: `/customer/branch/qr-code/${item.id}` });
    },
    async getTabel() {
      this.isLoading = true;
      const customerId = this.$route.params.customerId || null;
      try {
        if (customerId) {
          await this.$axios
            .get(
              `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer/${customerId}/customer-branches`,
              {
                params: this.params,
              }
            )
            .then(({ data }) => {
              this.listTable = data.list;
              this.totalData = data.total;
            });
        } else {
          await this.$axios
            .get(
              `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer-branches`,
              {
                params: this.params,
              }
            )
            .then(({ data }) => {
              this.listTable = data.list;
              this.totalData = data.total;
            });
        }
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    processChange: debounce(function () {
      this.getTabel();
    }, 500),
    onCopy(e) {
      this.$bvToast.toast(e.text, {
        title: "Successfuly Copy to clipboard",
        variant: "success",
        solid: true,
      });
    },
    sortChar,
  },
  watch: {
    params: {
      handler() {
        this.processChange();
      },
      deep: true,
    },
  },
};
</script>
